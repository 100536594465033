import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    section as sectionClass,
    grid,
    titleText,
    contentText,
    image,
    videoContainer,
    video,
    textWithMedia,
    onlyMedia,
} from './article-preview.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface IArticleProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ArticlePreview: React.FC<IArticleProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        content: { texts, media },
        sectionId,
        style,
        css,
    } = section;
    const [title, subtitle, description] = texts;

    const hasMedia = media.length > 0;
    const hasTextAndMedia = hasMedia && description;
    const hasOnlyMedia = hasMedia && !title && !description && !subtitle;

    return (
        <Section
            sectionId={sectionId}
            className={[
                sectionClass,
                hasTextAndMedia ? textWithMedia : '',
                hasOnlyMedia ? onlyMedia : '',
                className,
            ].join(' ')}
            classes={{
                container: grid,
                title: titleText,
                description: contentText,
            }}
            style={style}
            css={css}
            title={title}
            subtitle={subtitle}
            description={description}
            TitleTag={TitleTag}
            roundedSection={true}
        >
            {hasMedia && media[0].type.includes('image') && (
                <Image className={image} media={media} />
            )}
            {hasMedia && media[0].type.includes('video') && (
                <div className={videoContainer}>
                    <video className={video} controls>
                        <source src={media[0].url} type={media[0].type} />
                    </video>
                </div>
            )}
        </Section>
    );
};

export default ArticlePreview;
