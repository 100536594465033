// extracted by mini-css-extract-plugin
export var backgroundAnimation = "article-preview-module--backgroundAnimation--cf75c";
export var contentText = "article-preview-module--content-text--b44d6";
export var grid = "article-preview-module--grid--f8398";
export var image = "article-preview-module--image--35123";
export var onlyMedia = "article-preview-module--only-media--0a35b";
export var section = "article-preview-module--section--58e95";
export var textWithMedia = "article-preview-module--text-with-media--cd4f1";
export var titleText = "article-preview-module--title-text--59d67";
export var video = "article-preview-module--video--c2828";
export var videoContainer = "article-preview-module--video-container--faa9c";